<template>
    <DataForm :form="form" v-model="values" />
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import {BaseForm} from "@/types/form";
    import {SelectField, TextField} from "@/types/field";
    import {UrlValidationRule} from "@/types/validation";
    import {userStore} from "@/store/user";
    import { FieldOption } from '@/types/option';

    export default defineComponent({
        name: "SocialProfilesForm",
        props: {
            save: {
                type: Function,
                default: (): Promise<any> => Promise.resolve()
            }
        },
        setup(props) {

            //  Form
            const form = reactive(new BaseForm());
            form.addField(new TextField('facebook', 'social.facebook').addRule(new UrlValidationRule()))
            form.addField(new TextField('instagram', 'social.instagram').addRule(new UrlValidationRule()))
            form.addField(new TextField('youtube', 'social.youtube').addRule(new UrlValidationRule()))

            //  Values
            const values = reactive({
                facebook: userStore.getState().social.facebook,
                instagram: userStore.getState().social.instagram,
                youtube: userStore.getState().social.youtube,
            });

            //  Bind the save method
            props.save(() => {
                return new Promise((resolve, reject) => {
                    form.setError('').submit().then(() => {
                        userStore.updateSocialProfiles(values.facebook, values.instagram, values.youtube)
                            .then(() => resolve())
                            .catch(error => {
                                form.setError(error);
                                reject();
                            });
                    }).catch(() => reject());
                })
            });

            return {
                form,
                values
            }
        }
    })
</script>