
    import {defineComponent, reactive} from 'vue';
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import SocialProfilesForm from "@/pages/profile/SocialProfilesForm.vue";
    import Submenu from "@/layouts/components/Submenu.vue";
    import MobileMenu from "@/layouts/components/MobileMenu.vue";
    import {navigation} from "@/pages/profile/profile-menu";
    import router from "@/router";

    export default defineComponent({
        name: "SocialProfiles",
        components: {
            SocialProfilesForm,
            Submenu,
            MobileMenu,
        },
        setup() {
            const i18n = useTranslation();
            const notification = useNotification();
            let onSaveForm: Function = () : Promise<any> => Promise.resolve();
            const bindSaveMethod = (save: Function) => onSaveForm = save;
            const info = reactive({saving: false});
            const save = () => {
                if(!info.saving) {
                    info.saving = true;
                    onSaveForm().then(() => {
                        info.saving = false;
                        notification.success(i18n.translate('social.title'), i18n.translate('form.updated'))
                    }).catch(() => {
                        info.saving = false;
                    });
                }
            }
            return {
                bindSaveMethod,
                save,
                info,
                navigation,
                router,
            }
        }
    })
